// This file is migrated from UIComponents.

// UIComponents runs in some environments where window.navigator is undefined.
// See: https://git.hubteam.com/HubSpot/hubspot-book-builder/pull/88
let lowercasedUserAgent = window.navigator ? navigator.userAgent.toLowerCase() : '';
export const isEdge = () => lowercasedUserAgent.includes('edge/');
export const isFirefox = () => lowercasedUserAgent.includes('firefox');
export const isSafari = () => lowercasedUserAgent.includes('safari/') && !lowercasedUserAgent.includes('chrome/') && !isEdge();

/**
 * For faking `navigator.userAgent` in tests.
 * @param {string} newUserAgent
 */
export const setUserAgent = newUserAgent => {
  lowercasedUserAgent = newUserAgent.toLowerCase();
};