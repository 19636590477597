import { createGlobalStyle } from 'styled-components';
import { useIsKeyboardMode } from './KeyboardModeProvider';
import { classicLightCssVars } from 'foundations-theming/themes/classic/light/cssVars';
import { useThemeMetadata } from 'foundations-theming/components/ThemeProvider';
import { useEffect, useState } from 'react';
import devLogger from 'react-utils/devLogger';
const getCssVars = async (themeName, themeMode) => {
  if (themeName === 'classic') {
    if (themeMode === 'light') {
      return Promise.resolve(classicLightCssVars);
    }
  } else if (themeName === 'pink') {
    if (themeMode === 'light') {
      return (await import('./mock-themes/pink/light/cssVars')).pinkLightCssVars;
    }
  } else if (themeName === 'next') {
    if (themeMode === 'light') {
      return (await import('foundations-theming/themes/next/light/cssVars')).nextLightCssVars;
    } else if (themeMode === 'dark') {
      return (await import('foundations-theming/themes/next/dark/cssVars')).nextDarkCssVars;
    }
  }
  throw new Error('Unsupported theme and mode combination.');
};
export const GlobalStyles = createGlobalStyle(({
  theme: _theme
}) => {
  const theme = _theme;
  const {
    themeName,
    themeMode
  } = useThemeMetadata();
  const isKeyboardMode = useIsKeyboardMode();
  const [cssVars, setCSSVars] = useState(classicLightCssVars);
  useEffect(() => {
    getCssVars(themeName, themeMode).then(cssVarsObject => {
      setCSSVars(cssVarsObject);
    }).catch(error => {
      devLogger.warn({
        message: `Failed to load CSS Variables with error: ${error}`,
        key: 'foundations-components:global-styles:css-vars-load-failure'
      });
    });
  }, [themeName, themeMode]);
  return {
    ':root': Object.assign({}, cssVars),
    '*': {
      boxSizing: 'border-box'
    },
    '*::before': {
      boxSizing: 'border-box'
    },
    '*::after': {
      boxSizing: 'border-box'
    },
    /*
     * Removes default focus styles for non-input elements.
     * This is the replacement for the `hubspot-disable-focus-styles` CSS class in UIComponents.
     */
    '*:focus:not(input):not(textarea):not(.keep-default-focus-styles)': {
      outline: isKeyboardMode ? undefined : '0'
    },
    body: Object.assign({}, theme.typography['body-100'], {
      color: theme.color['text-primary-default']
    }),
    // Make the background unscrollable while FullScreen is open
    'html.FullScreenBackground, body.FullScreenBackground': {
      overflow: 'hidden'
    },
    // An element with this class is used to measure the scrollbar width
    '.FullScreen-scrollbar-measure': {
      position: 'absolute',
      top: '-9999px',
      width: '50px',
      height: '50px',
      overflow: 'scroll'
    },
    // Modifier to suppress navigation (used by the transitional EditorPage)
    '.space-sword--editor': {
      '#hs-nav-v4, #placeholder-vertical-nav, #trial-banner-block': {
        display: 'none'
      },
      '.page, .app': {
        marginInlineStart: theme.space['0'],
        inlineSize: 'auto'
      }
    }
  };
});