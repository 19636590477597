// @ts-expect-error Untyped module
import getLang from 'I18n/utils/getLang';
function getIsIncludedInKey(lang, swapLangs) {
  return Object.prototype.hasOwnProperty.call(swapLangs, lang);
}
const getValueIfExistsOnObject = (lang, swapLangs) => {
  if (getIsIncludedInKey(lang, swapLangs)) {
    return swapLangs[lang];
  }
  return lang;
};

// Knowledge Base pages are hosted on knowledge.hubspot.com, and the language specific is knowledge.hubspot.com/jp/
const knowledgeBaseDomain = 'knowledge.hubspot.com';
const knowledgeBaseLangRegex = new RegExp(/knowledge.hubspot.com\/\w{2}\//);
const knowledgeSwapLang = {
  ja: 'jp',
  zh: 'zh-tw'
};
const knowledgeBaseIgnoreList = ['ko', 'th', 'zh-cn'];
export const getKnowledgeBaseUrl = url => {
  const lang = getLang();
  const urlBaseMatch = knowledgeBaseLangRegex.test(url);
  const knowledgeLang = getValueIfExistsOnObject(lang, knowledgeSwapLang);
  if (knowledgeBaseIgnoreList.includes(lang) || urlBaseMatch || knowledgeLang === 'en') {
    return url;
  }
  return url.replace(knowledgeBaseDomain, `${knowledgeBaseDomain}/${knowledgeLang}`);
};

// HubSpot Legal pages are hosted on legal.hubspot.com, and the language as legal.hubspot.com/jp/

const hubspotLegalBaseDomain = 'legal.hubspot.com';
const hubSpotLegalLangRegex = new RegExp(/legal.hubspot.com\/\w{2}\//);
const hubSpotLegalSwapLang = {
  ja: 'jp',
  pt: 'br'
};
export const hubLegalSpotAllowList = ['ja', 'de', 'es', 'fr', 'pt'];
export const getHubSpotLegalUrl = (url, __overrideLangCode) => {
  const lang = __overrideLangCode || getLang();
  const urlBaseMatch = hubSpotLegalLangRegex.test(url);
  const hubspotLegalLang = getValueIfExistsOnObject(lang, hubSpotLegalSwapLang);
  if (!hubLegalSpotAllowList.includes(lang) || urlBaseMatch || lang === 'en') {
    return url;
  }
  return url.replace(hubspotLegalBaseDomain, `${hubspotLegalBaseDomain}/${hubspotLegalLang}`);
};

// HubSpot english pages are hosted on hubspot.com, and the language specific is hubspot.jp (see pt, as its an exception)

const hubSpotBaseUrl = 'www.hubspot.com';
const hubSpotLangRegex = new RegExp(/www.hubspot.com\/\w{2}\//);
const hubSpotWithNewSuffixDict = {
  ja: 'www.hubspot.jp',
  de: 'www.hubspot.de',
  es: 'www.hubspot.es',
  fr: 'www.hubspot.fr',
  pt: 'br.hubspot.com'
};
export const hubSpotAllowList = ['ja', 'de', 'es', 'fr', 'pt'];
export const getHubGenericLink = (url, __overrideLangCode) => {
  const langKey = __overrideLangCode || getLang();
  const urlBaseMatch = hubSpotLangRegex.test(url);
  const hubSpotBaseUrlWithNewNewSuffix = getValueIfExistsOnObject(langKey, hubSpotWithNewSuffixDict);
  if (!hubSpotAllowList.includes(langKey) || urlBaseMatch || langKey === 'en') {
    return url;
  }
  return url.replace(hubSpotBaseUrl, hubSpotBaseUrlWithNewNewSuffix);
};