import { useTheme } from 'styled-components';
import { styleCategoryMap } from '../../utils/styleCategoryMap';
import { SHORTHAND_STYLE_PROPS_MAP } from '../../utils/shorthandStylePropsMap';
const MARGIN_PROPERTIES = new Set(['margin', 'marginBlock', 'marginBlockStart', 'marginBlockEnd', 'marginInline', 'marginInlineStart', 'marginInlineEnd']);

/**
 * Converts a provided CSS property and value to a CSS object.
 * @param key - The CSS property.
 * @param value - The CSS value.
 * @returns An object of CSS properties and values.
 */
const getCSSStylesForValue = (key, value) => {
  // If the key is a shordhand property, create a new object with the longhand properties where the same value is applied to each property.
  if (key in SHORTHAND_STYLE_PROPS_MAP) {
    const longhandKeys = SHORTHAND_STYLE_PROPS_MAP[key];
    const longhandStyles = {};
    longhandKeys.forEach(longhandKey => {
      longhandStyles[longhandKey] = value;
    });
    return longhandStyles;
  }

  // If the key isn't a shorthand property, return the key-value pair directly.
  return {
    [key]: value
  };
};

/**
 * Handles special cases for space values, including 'auto' margins, negative margin values, and "!important" space values.
 * This function centralizes all space-related special case handling
 * in one place.
 *
 * @param key - The style property to check.
 * @param value - The value to check for special handling.
 * @param spaceStyles - Optional theme space object for handling negative values and "!important" values.
 * @returns A CSS styles object if the value should be specially handled,
 * null otherwise.
 *
 * @example
 * handleSpaceValue('marginInline', 'auto') // Returns { marginInline: 'auto' }
 * handleSpaceValue('marginBlock', '-200', theme.space) // Returns { marginBlock: '-8px' }
 * handleSpaceValue('marginBlock', '200') // Returns null (handled by theme system)
 */
const handleSpaceValue = (key, value, spaceStyles) => {
  // Handle margin 'auto' case
  if (MARGIN_PROPERTIES.has(key) && (value === 'auto' || value === 'auto !important')) {
    return getCSSStylesForValue(key, value);
  }

  // Handles negative margin values and "!important" space values
  if (spaceStyles && typeof value === 'string') {
    const isNegative = value.startsWith('-');
    const isImportant = value.endsWith('!important');
    let tokenKey = value;
    if (isNegative) {
      tokenKey = tokenKey.slice(1);
    }
    if (isImportant) {
      tokenKey = tokenKey.replace(' !important', '');
    }
    const tokenValue = spaceStyles[tokenKey];
    if (tokenValue) {
      let finalValue = tokenValue;
      if (isNegative) {
        finalValue = `-${finalValue}`;
      }
      if (isImportant) {
        finalValue = `${finalValue} !important`;
      }
      return getCSSStylesForValue(key, finalValue);
    }
  }

  // No special case applied
  return null;
};

/**
 * This hook maps token properties provided to components (e.g. Text, View,
 * Flex) to their corresponding CSS values defined within a theme.
 * For properties that do not correspond to a theme category, it uses the
 * value directly without theme validation.
 *
 * @param tokenProps - The token properties provided to the component.
 * @param opts - An options object for customizing styles after initial computation.
 *
 * @returns A new object containing valid CSS properties and their values. E.g:
 *
 * `<View padding="1000">...</View>`
 *
 * Here, the `padding` prop is looked up in the `styleCategoryMap` object
 * to find the corresponding theme category, which is `space` in this case.
 * The padding value `1000` is then looked up in the `space`
 * category of the theme object, and the corresponding value is returned.
 * The object returned from the function would then be: `{ padding: '40px' }`.
 */
export const useMapTokenPropsToCSS = (tokenProps, opts = {}) => {
  const theme = useTheme();

  // Temp type to satisfy TypeScript. Will be `T` at the end.
  let cssStyles = {};
  Object.keys(tokenProps).forEach(propKey => {
    const key = propKey;
    const value = tokenProps[key];
    if (value) {
      // Necessary type cast because the `styleCategoryMap` object contains
      // only all possible style props for which token values have been defined
      // in the design system. In cases where `key` is a style property without
      // tokens defined, like `width`, TypeScript would throw an error.
      const themeCategory = styleCategoryMap[key];
      const themeCategoryStyles = themeCategory ? theme[themeCategory] : undefined;

      // Try special cases first
      const specialCaseStyles = handleSpaceValue(key, value, themeCategory === 'space' ? themeCategoryStyles : undefined);
      if (specialCaseStyles) {
        cssStyles = Object.assign({}, cssStyles, specialCaseStyles);
        return;
      }

      // When a style property doesn't have a corresponding theme category,
      // like `width`, we use the value directly without theme validation.
      if (!themeCategoryStyles) {
        cssStyles = Object.assign({}, cssStyles, getCSSStylesForValue(key, value));
      } else {
        // Otherwise, we know that the style property has a corresponding
        // theme category, so we look up the token value in the theme object.
        const themeValue = themeCategoryStyles[value];
        if (themeValue) {
          cssStyles = Object.assign({}, cssStyles, getCSSStylesForValue(key, themeValue));
        }
      }
    }
  });
  if (opts.customizeStyles) {
    cssStyles = opts.customizeStyles({
      theme,
      tokenProps,
      cssStyles: Object.assign({}, cssStyles)
    });
  }
  return cssStyles;
};