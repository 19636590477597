export const getVariantStyles = use => {
  switch (use) {
    case 'dark':
      return {
        backgroundColor: 'fill-surface-recessed',
        minBlockSize: '56px',
        paddingBlock: '200'
      };
    case 'filter-bar':
      return {
        // 16px instead of 18px since token for 18px is not available
        paddingBlock: '400',
        paddingInline: '0'
      };
    case 'tabs':
      return {
        borderBlockEndStyle: 'solid',
        borderBlockEndWidth: '100',
        borderBlockEndColor: 'border-core-default',
        flexWrap: 'nowrap'
      };
    case 'default':
    default:
      return {};
  }
};