/**
 * This function is a customization function that adds `!important` to all CSS
 * properties. It is intended to be used with the `customizeStyles` option in
 * the `useMapTokenPropsToCSS` hook.
 *
 * @param matcher A regular expression to match the CSS property names that should have `!important` added.
 * @returns CSSStyles
 */
export const customizeStylesWithImportant = matcher => {
  return opts => {
    const {
      cssStyles
    } = opts;
    Object.keys(cssStyles).forEach(key => {
      if (matcher && !matcher.test(key)) {
        return;
      }
      const value = cssStyles[key];
      cssStyles[key] = `${value} !important`;
    });
    return cssStyles;
  };
};