require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "I18n/lang",
  context: {
    "af": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./af.lyaml?sporks" /* webpackChunkName: "I18n-lang-af" */); },
    "ar-eg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ar-eg.lyaml?sporks" /* webpackChunkName: "I18n-lang-ar-eg" */); },
    "bg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./bg.lyaml?sporks" /* webpackChunkName: "I18n-lang-bg" */); },
    "bn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./bn.lyaml?sporks" /* webpackChunkName: "I18n-lang-bn" */); },
    "ca-es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ca-es.lyaml?sporks" /* webpackChunkName: "I18n-lang-ca-es" */); },
    "cs": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./cs.lyaml?sporks" /* webpackChunkName: "I18n-lang-cs" */); },
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./da.lyaml?sporks" /* webpackChunkName: "I18n-lang-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./de.lyaml?sporks" /* webpackChunkName: "I18n-lang-de" */); },
    "el": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./el.lyaml?sporks" /* webpackChunkName: "I18n-lang-el" */); },
    "en-gb": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./en-gb.lyaml?sporks" /* webpackChunkName: "I18n-lang-en-gb" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./en.lyaml?sporks" /* webpackChunkName: "I18n-lang-en" */); },
    "es-mx": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./es-mx.lyaml?sporks" /* webpackChunkName: "I18n-lang-es-mx" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./es.lyaml?sporks" /* webpackChunkName: "I18n-lang-es" */); },
    "et": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./et.lyaml?sporks" /* webpackChunkName: "I18n-lang-et" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./fi.lyaml?sporks" /* webpackChunkName: "I18n-lang-fi" */); },
    "fr-ca": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./fr-ca.lyaml?sporks" /* webpackChunkName: "I18n-lang-fr-ca" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./fr.lyaml?sporks" /* webpackChunkName: "I18n-lang-fr" */); },
    "he-il": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./he-il.lyaml?sporks" /* webpackChunkName: "I18n-lang-he-il" */); },
    "hi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./hi.lyaml?sporks" /* webpackChunkName: "I18n-lang-hi" */); },
    "hr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./hr.lyaml?sporks" /* webpackChunkName: "I18n-lang-hr" */); },
    "hu": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./hu.lyaml?sporks" /* webpackChunkName: "I18n-lang-hu" */); },
    "id": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./id.lyaml?sporks" /* webpackChunkName: "I18n-lang-id" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./it.lyaml?sporks" /* webpackChunkName: "I18n-lang-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ja.lyaml?sporks" /* webpackChunkName: "I18n-lang-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ko.lyaml?sporks" /* webpackChunkName: "I18n-lang-ko" */); },
    "lt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./lt.lyaml?sporks" /* webpackChunkName: "I18n-lang-lt" */); },
    "ms": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ms.lyaml?sporks" /* webpackChunkName: "I18n-lang-ms" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./nl.lyaml?sporks" /* webpackChunkName: "I18n-lang-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./no.lyaml?sporks" /* webpackChunkName: "I18n-lang-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./pl.lyaml?sporks" /* webpackChunkName: "I18n-lang-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./pt-br.lyaml?sporks" /* webpackChunkName: "I18n-lang-pt-br" */); },
    "pt-pt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./pt-pt.lyaml?sporks" /* webpackChunkName: "I18n-lang-pt-pt" */); },
    "ro": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ro.lyaml?sporks" /* webpackChunkName: "I18n-lang-ro" */); },
    "ru": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./ru.lyaml?sporks" /* webpackChunkName: "I18n-lang-ru" */); },
    "sk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./sk.lyaml?sporks" /* webpackChunkName: "I18n-lang-sk" */); },
    "sl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./sl.lyaml?sporks" /* webpackChunkName: "I18n-lang-sl" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./sv.lyaml?sporks" /* webpackChunkName: "I18n-lang-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./th.lyaml?sporks" /* webpackChunkName: "I18n-lang-th" */); },
    "tl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./tl.lyaml?sporks" /* webpackChunkName: "I18n-lang-tl" */); },
    "tr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./tr.lyaml?sporks" /* webpackChunkName: "I18n-lang-tr" */); },
    "uk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./uk.lyaml?sporks" /* webpackChunkName: "I18n-lang-uk" */); },
    "vi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./vi.lyaml?sporks" /* webpackChunkName: "I18n-lang-vi" */); },
    "x-pseudo": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./x-pseudo.lyaml?sporks" /* webpackChunkName: "I18n-lang-x-pseudo" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./zh-cn.lyaml?sporks" /* webpackChunkName: "I18n-lang-zh-cn" */); },
    "zh-hk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./zh-hk.lyaml?sporks" /* webpackChunkName: "I18n-lang-zh-hk" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.1621/node_modules/bender-lyaml-loader/lib/index.js??ruleSet[1].rules[13]!./zh-tw.lyaml?sporks" /* webpackChunkName: "I18n-lang-zh-tw" */); }  }
};
